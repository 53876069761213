import { useEffect, useRef } from "react";

const useEffectSinEjecucionInicial = (fn, inputs) => {
  const renderizado = useRef(false);

  useEffect(() => {
    if (renderizado.current) {
      return fn();
    }
    renderizado.current = true;
  }, inputs);
};

export { useEffectSinEjecucionInicial };
