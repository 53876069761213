import { useRef, useState } from "react";

const useTyping = () => {
  const inputReal = useRef("");

  const dejoDeEscribir = (texto, delay = 400) => {
    inputReal.current = texto;
    return new Promise(resolve => {
      setTimeout(() => resolve(inputReal.current == texto), delay);
    });
  };

  return [dejoDeEscribir];
};

export { useTyping };
